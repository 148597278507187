body {
  --andes-color-blue-100: rgba(71, 154, 209, 0.1);
  --andes-color-blue-150: rgba(71, 154, 209, 0.15);
  --andes-color-blue-200: rgba(71, 154, 209, 0.2);
  --andes-color-blue-300: rgba(71, 154, 209, 0.3);
  --andes-color-blue-400: rgba(71, 154, 209, 0.4);
  --andes-color-blue-500: #009ee3;
  --andes-color-blue-600: #007eb5;
  --andes-color-blue-700: #005e88;
  --andes-color-blue-800: #004766;
  --andes-color-fill-brand: var(--andes-color-blue-500);
  --andes-color-text-brand: #fff;
  --andes-landings-color-fill-brand: #00bcff;
  --andes-landings-color-fill-brand-secondary: #020b7c;
  --andes-landings-color-fill-brand-tertiary: #068ee4;
  --andes-landings-color-middle: #0b105b;
  --andes-landings-button-theme-white-background: #ffffff;
  --andes-landings-button-theme-white-text-color: #000000;
  --andes-landings-button-theme-white-hover-background: rgba(255, 255, 255, 0.6);
  --andes-landings-button-theme-white-pressed-background: rgba(255, 255, 255, 0.7);
}

header[data-theme=yellowblue-light] {
  background: #fff;
}

header[data-theme=yellowblue-light] [class*="nav-header-guest__link--login_"] {
  color: var(--andes-color-blue-500, #009ee3);
  background-color: rgba(71, 154, 209, .15);
}

header[data-theme=yellowblue-light] [class*="nav-header-guest__link--login_"]:hover {
  background-color: rgba(71, 154, 209, .2);
}